<template>
  <div class="py-bw-5">
    <b-container fluid class="py-bw-5 section-header">
      <b-container>
        <b-row class="mb-4">
          <b-col cols="12">
            <h1 class="display-bw-3 mb-4">
              {{ $t("classes.header.title") }}
            </h1>
            <p class="p-bw mb-5 px-5">
              {{ $t("classes.header.content") }}
            </p>
          </b-col>
        </b-row>
      </b-container>
      <b-row class="py-bw-5">
        <b-col class="px-0">
          <swiper
            ref="trainersSwiper"
            :options="swiperOptionTrainers"
            :auto-destroy="true"
            :delete-instance-on-destroy="true"
            :cleanup-styles-on-destroy="true"
          >
            <swiper-slide :key="trainer.id" v-for="trainer in trainers">
              <div class="card-trainer">
                <img
                  :src="require(`@/assets/imgs/trainers/${trainer.imgSrc}`)"
                  :alt="trainer.name"
                />
              </div>
            </swiper-slide>
          </swiper>
          <!-- <b-button
            variant="dark"
            class="w-25 mt-5 mr-5"
            :to="{ name: 'About', hash: '#trainers'}"
          >
            Become a trainer
          </b-button>
          <b-button
            variant="outline-primary"
            class="w-25 mt-5"
            :to="{ name: 'About', hash: '#trainers'}"
            disabled
          >
            Meet our team
          </b-button> -->
        </b-col>
      </b-row>
    </b-container>

    <b-container
      id="virtualWorkoutsClasses"
      class="pb-8 section--virtual-classes"
    >
      <b-row class="mb-5">
        <b-col cols="12">
          <p class="p-bw-bold mx-auto mb-2">
            {{ $t("classes.section1.subtitle") }}
          </p>
          <h2 class="h2-title">
            {{ $t("classes.section1.title") }}
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div>
            <b-tabs pills fill content-class="mt-bw-90">
              <b-tab title="Gym classes" active class="shadow">
                <div class="text-left">
                  <b-tabs vertical nav-wrapper-class="w-50">
                    <b-tab>
                      <template #title>
                        <div class="d-flex flex-row align-items-center p-3">
                          <div class="p-bw-bold highlighter bg-success mr-4">
                            {{ $t("common.gym") }}
                          </div>
                          <div class="p-bw-bold mr-3">1 class pass</div>
                          <div class="h1-bw ml-auto">20€</div>
                        </div>
                      </template>
                      <div>
                        <b-card
                          border-variant="secondary"
                          bg-variant="secondary"
                          class="card-bw text-center"
                        >
                          <div class="d-flex flex-column">
                            <p class="p-bw-bold highlighter bg-success mx-auto">
                              {{ $t("common.gym") }}
                            </p>
                            <h1 class="h1-bw mb-4">1 class pass</h1>
                            <h1 class="h1-bw mb-3">20€</h1>
                            <p class="p-bw-bold">
                              What's included in your purchase?
                            </p>
                            <div class="p-bw mb-5">
                              <li>1 awesome workout with our coaches</li>
                              <li>
                                Workout with your community in a safe
                                environment
                              </li>
                              <li>Socialize while getting fit</li>
                            </div>
                            <b-button
                              variant="dark"
                              class="w-100"
                              href="https://widget.fitogram.pro/barliner-workout/?w=/studio"
                              target="_blank"
                            >
                              Buy now
                            </b-button>
                          </div>
                        </b-card>
                      </div>
                    </b-tab>
                    <b-tab active>
                      <template #title>
                        <div class="d-flex flex-row align-items-center p-3">
                          <div class="p-bw-bold highlighter bg-success mr-4">
                            {{ $t("common.gym") }}
                          </div>
                          <div class="p-bw-bold mr-3">5 classes pass</div>
                          <div class="h1-bw ml-auto">90€</div>
                        </div>
                      </template>
                      <div>
                        <b-card
                          border-variant="secondary"
                          bg-variant="secondary"
                          class="card-bw text-center"
                        >
                          <p class="p-bw-bold highlighter bg-success mx-auto">
                            {{ $t("common.gym") }}
                          </p>
                          <h1 class="h1-bw mb-4">5 classes pass</h1>
                          <h1 class="h1-bw mb-3">90€</h1>
                          <p class="p-bw-bold">
                            What's included in your purchase?
                          </p>
                          <div class="p-bw mb-5">
                            <li>5 awesome workouts with our coaches</li>
                            <li>
                              Workout with your community in a safe environment
                            </li>
                            <li>Socialize while getting fit</li>
                            <li>Passes are valid for six months</li>
                          </div>
                          <b-button
                            variant="dark"
                            class="w-100"
                            href="https://widget.fitogram.pro/barliner-workout/?w=/studio"
                            target="_blank"
                          >
                            Buy now
                          </b-button>
                        </b-card>
                      </div>
                    </b-tab>

                    <b-tab>
                      <template #title>
                        <div class="d-flex flex-row align-items-center p-3">
                          <div class="p-bw-bold highlighter bg-success mr-4">
                            {{ $t("common.gym") }}
                          </div>
                          <div class="p-bw-bold mr-3">10 classes pass</div>
                          <div class="h1-bw ml-auto">160€</div>
                        </div>
                      </template>
                      <div>
                        <b-card
                          border-variant="secondary"
                          bg-variant="secondary"
                          class="card-bw text-center"
                        >
                          <p class="p-bw-bold highlighter bg-success mx-auto">
                            {{ $t("common.gym") }}
                          </p>
                          <h1 class="h1-bw mb-4">10 class pass</h1>
                          <h1 class="h1-bw mb-3">160€</h1>
                          <p class="p-bw-bold">
                            What's included in your purchase?
                          </p>
                          <div class="p-bw mb-5">
                            <li>10 awesome gym workouts with our coaches</li>
                            <li>
                              Workout with your community in a safe environment
                            </li>
                            <li>Socialize while getting fit</li>
                            <li>Passes are valid for twelve months</li>
                          </div>
                          <b-button
                            variant="dark"
                            class="w-100"
                            href="https://widget.fitogram.pro/barliner-workout/?w=/studio"
                            target="_blank"
                          >
                            Buy now
                          </b-button>
                        </b-card>
                      </div>
                    </b-tab>

                    <b-tab>
                      <template #title>
                        <div class="d-flex flex-row align-items-center p-3">
                          <div class="p-bw-bold highlighter bg-success mr-4">
                            {{ $t("common.gym") }}
                          </div>
                          <div class="p-bw-bold mr-3">Auto Renewal</div>
                          <div class="h1-bw ml-auto">150€</div>
                        </div>
                      </template>
                      <div>
                        <b-card
                          border-variant="secondary"
                          bg-variant="secondary"
                          class="card-bw text-center"
                        >
                          <p class="p-bw-bold highlighter bg-success mx-auto">
                            {{ $t("common.gym") }}
                          </p>
                          <h1 class="h1-bw mb-4">Auto Renewal</h1>
                          <h1 class="h1-bw mb-3">150€</h1>
                          <p class="p-bw-bold">
                            What's included in your purchase?
                          </p>
                          <div class="p-bw mb-5">
                            <li>
                              5 awesome workouts you can access every week
                            </li>
                            <li>
                              Workout with your community in a safe environment
                            </li>
                            <li>Socialize while getting fit</li>
                          </div>
                          <b-button
                            variant="dark"
                            class="w-100"
                            href="https://widget.fitogram.pro/barliner-workout/?w=/studio"
                            target="_blank"
                          >
                            Buy now
                          </b-button>
                        </b-card>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </b-tab>
              <b-tab title="All-access memberships">
                <b-row>
                  <b-col>
                    <b-card
                      border-variant="secondary"
                      bg-variant="secondary"
                      class="card-bw text-center shadow px-5"
                    >
                      <p class="p-bw-bold mx-auto">All-access</p>
                      <h1 class="h1-bw mb-4">Light membership</h1>
                      <h1 class="h1-bw mb-3">120€</h1>
                      <p class="p-bw-bold">What's included in your purchase?</p>
                      <div class="p-bw mb-5">
                        <li>
                          2 awesome workouts you can access every week with no
                          minimum contract
                        </li>
                        <li>
                          Workout with your community in a safe environment
                        </li>
                        <li>Socialize while getting fit</li>
                      </div>
                      <b-button
                        variant="dark"
                        class="w-100"
                        href="https://widget.fitogram.pro/barliner-workout/?w=/studio"
                        target="_blank"
                      >
                        Buy now
                      </b-button>
                    </b-card>
                  </b-col>
                  <b-col>
                    <b-card
                      border-variant="secondary"
                      bg-variant="secondary"
                      class="card-bw text-center shadow px-5"
                    >
                      <p class="p-bw-bold mx-auto">All-access</p>
                      <h1 class="h1-bw mb-4">Full memberships</h1>
                      <h1 class="h1-bw mb-3">150€</h1>
                      <p class="p-bw-bold">What's included in your purchase?</p>
                      <div class="p-bw mb-5">
                        <li>5 awesome workouts you can access every week</li>
                        <li>
                          Workout with your community in a safe environment
                        </li>
                        <li>Socialize while getting fit</li>
                      </div>
                      <b-button
                        variant="dark"
                        class="w-100"
                        href="https://widget.fitogram.pro/barliner-workout/?w=/studio"
                        target="_blank"
                      >
                        Buy now
                      </b-button>
                    </b-card>
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid class="bg-accent section--personal-training">
      <b-container class="py-8 text-md-left section-personal-training">
        <b-row>
          <b-col cols="12" md="6">
            <div class="img-classes-group-a" alt="Barliner Workout"></div>
          </b-col>
          <b-col cols="12" md="6" class="px-5 my-auto mt-sm-6">
            <div class="pl-5">
              <p class="p-bw-bold mb-2">
                {{ $t("classes.section2.subtitle") }}
              </p>
              <h2 class="h2-bw mb-4">
                {{ $t("classes.section2.title") }}
              </h2>
              <p class="p-bw">
                {{ $t("classes.section2.content") }}
              </p>
              <b-button
                variant="dark"
                class="mt-4 w-75"
                :to="{ name: 'PersonalCoaching' }"
              >
                {{ $t("classes.section2.buttonLabel") }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-container>

    <contact></contact>
  </div>
</template>

<script>
export default {
  name: 'Inclusive',
  components: {
    Contact: () => import('@/components/Contact')
  },
  data () {
    return {
      trainers: [
        {
          id: 1,
          name: 'Denis',
          imgSrc: 'trainer-snip1.jpg'
        },
        {
          id: 2,
          name: 'Denis',
          imgSrc: 'trainer-snip2.jpg'
        },
        {
          id: 3,
          name: 'Denis',
          imgSrc: 'trainer-snip3.jpg'
        },
        {
          id: 4,
          name: 'Denis',
          imgSrc: 'trainer-snip4.jpg'
        },
        {
          id: 5,
          name: 'Denis',
          imgSrc: 'trainer-snip5.jpg'
        },
        {
          id: 6,
          name: 'Denis',
          imgSrc: 'trainer-snip2.jpg'
        },
        {
          id: 7,
          name: 'Denis',
          imgSrc: 'trainer-snip4.jpg'
        },
        {
          id: 8,
          name: 'Denis',
          imgSrc: 'trainer-snip1.jpg'
        },
        {
          id: 9,
          name: 'Denis',
          imgSrc: 'trainer-snip5.jpg'
        },
        {
          id: 10,
          name: 'Denis',
          imgSrc: 'trainer-snip3.jpg'
        }
      ],
      swiperOptionTrainers: {
        slidesPerView: 5,
        spaceBetween: 0,
        freeMode: true,
        grabCursor: true,
        loop: true,
        initialSlide: 0,
        autoplay: {
          delay: 1500,
          disableOnInteraction: true
        },
        speed: 800,
        breakpoints: {
          1024: {
            slidesPerView: 5,
            spaceBetween: 0
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 0
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 0
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 0
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.section-header {
  .card-trainer {
    height: 480px;
    margin-right: 0.75rem;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.section-personal-training {
  .img-classes-group-a {
    @extend .img-background;
    height: 100%;
    min-height: 492px;
    background-image: url("../assets/imgs/classes_group_a_3x.jpg");
    background-size: cover;
    background-position: center;
  }
}
</style>
